"use strict";

var sidebarControl = (function () {
  var hamburger = $(".hamburger");

  function init() {
    hamburger.on("click", function () {
      $(".navbar-collapse").toggleClass("show");
      hamburger.toggleClass("translate");
      $(".hamburger .line-mid").toggleClass("translate");
      $(".hamburger .line-bot").toggleClass("translate");
      $(".navbar-brand").toggleClass("side-active");
      $("section").toggleClass("side-active");
    });

    $("section").on("click", function () {
      $(".navbar-collapse").removeClass("show");
      hamburger.removeClass("translate");
      $(".hamburger .line-mid").removeClass("translate");
      $(".hamburger .line-bot").removeClass("translate");
      $(".navbar-brand").removeClass("side-active");
      $("section").removeClass("side-active");
    });
  }

  init();
})();

var titlePost = (function () {
  function init() {
    var title = $(".caption>span>h5");

    var length = 40;

    for (let i = 0; i < title.length; i++) {
      var trimmedStr =
        title[i].textContent.length > length
          ? title[i].textContent.substring(0, length - 3) + "..."
          : title[i].textContent;
    //   console.log(trimmedStr);
      title[i].innerText = trimmedStr;
    }
    1;
  }

  init();
})();

var titleBerita = (function () {
  function init() {
    var title = $(".post-content>.title-post");

    var length = 62;

    for (let i = 0; i < title.length; i++) {
      var trimmedStr =
        title[i].textContent.length > length
          ? title[i].textContent.substring(0, length - 3) + "..."
          : title[i].textContent;
    //   console.log(trimmedStr);
      title[i].innerText = trimmedStr;
    }
    1;
  }

  init();
})();

var postBody = (function () {
  function init() {
    var title = $(".post-content>.body-post");

    var length = 185;

    for (let i = 0; i < title.length; i++) {
      var trimmedStr =
        title[i].textContent.length > length
          ? title[i].textContent.substring(0, length - 3) + "..."
          : title[i].textContent;
    //   console.log(trimmedStr);
        var removeLine = trimmedStr.replace(/\n/g, " ");
        var resultText = removeLine;
      title[i].innerText = resultText;
    }
  }

  init();
})();


